<template>
  <section id="hero">
    <v-img
      :height="minHeight"
      :src="require('@/assets/00032.jpg')"
      class="white--text"
      gradient="to bottom, rgba(11, 28, 61, .5), rgba(0,0,0,.4)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center mr-auto"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading
            size="text-h3"
            weight="medium"
            class="text-left"
          >
            <!--            <base-img-->
            <!--              :src="require(`@/assets/logo-2.png`)"-->
            <!--              contain-->
            <!--              :max-width="maxWidthImg"-->
            <!--              width="100%"-->
            <!--              class="mr-auto mb-3"-->
            <!--            />-->
            <!--            <span style="color: #87d3d9">АЙТИМУС</span> -->
            ПК АПИУЗ
          </base-heading>
          <base-subheading
            v-if="$vuetify.breakpoint.mdAndUp"
            weight="regular"
          >
            Программный комплекс автоматизации процесса подбора исполнителей на выполнение работ или услуг.
            <br>
            Комплекс зарегистрирован в Реестре Российского программного обеспечения.
            <a
              style="cursor: pointer; color: aquamarine"
              target="_blank"
              href="https://reestr.digital.gov.ru/reestr/947769/"
            >
              Реестровая запись №14702 от 26.08.2022</a>
          </base-subheading>
          <base-body v-if="!$vuetify.breakpoint.mdAndUp">
            Программный комплекс автоматизации процесса подбора исполнителей на выполнение работ или оказание услуг по заданным критериям на заказы, размещаемые организациями.
            <br>
            Комплекс зарегистрирован в Реестре Российского программного обеспечения.
            <a
              style="cursor: pointer;color: aquamarine"
              target="_blank"
              href="https://reestr.digital.gov.ru/reestr/947769/"
            >
              Реестровая запись №14702 от 26.08.2022
            </a>
          </base-body>
          <!--          <div-->
          <!--            v-if="1==0"-->
          <!--            :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"-->
          <!--            class="d-flex flex-wrap"-->
          <!--          >-->
          <!--            <base-btn>-->
          <!--              Discover More-->
          <!--            </base-btn>-->
          <!--            <span class="font-weight-bold ml-6 mr-4 my-4">or</span>-->
          <!--            <base-btn-->
          <!--              :ripple="false"-->
          <!--              class="pa-1"-->
          <!--              height="auto"-->
          <!--              text-->
          <!--            >-->
          <!--              Get Started Now-->
          <!--            </base-btn>-->
          <!--          </div>-->
        </v-responsive>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',
    components: {},
    provide: {
      theme: { isDark: true },
    },
    computed: {
      maxWidthImg () {
        return this.$vuetify.breakpoint.mdAndUp ? '290px' : '190px'
      },
      minHeight () {
        const height = '100vh' // this.$vuetify.breakpoint.mdAndUp ? '100vh' : '100vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
  }
</script>
